import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ApiService } from '../network/service';
import url from '../network/url';
import Loading from '../components/Loading';
import BreadCrumb from '../common/BreadCrumb';
import NotFound from '../components/NotFound';
import { RiDownloadCloud2Fill } from 'react-icons/ri'
// import { convertBlobToBase64 } from '../utils/index'
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const ProductFlyer = () => {
  const { productId } = useParams();
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const [product, setProduct] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [download, setDownload] = useState(false);

  useEffect(() => {
    fetchFlyer(productId);
  }, [productId]);

  const fetchFlyer = async (productId) => {
    setLoading(true);
    const formdata = new FormData();

    formdata.append('product_id', productId);

    await ApiService(url.flyer, formdata, 'POST').then((response) => {
      if (response.code === 200) {
        setProduct(response.data);
        setLoading(false);
      } else {
        setError('Product not found.');
        setLoading(false);
      }
    });
  };

  const breadcrumb = [
    {
      path: `/products/${productId}`,
      name: `${product.product_name}`
    }
  ];

  return (
    <div className="pb-3">
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: 'calc(100vh - 70px)' }}
        >
          <Loading />
        </div>
      )}

      {!loading && product && (
        <div className="container" style={{ marginTop: '5em' }}>
          <div className="bread-crumb-container mb-4 d-flex align-items-center justify-content-between p-2">
            <BreadCrumb routeList={breadcrumb} />
            {/* <div className="download"> */}
              {/* <button onClick={handleDownloadPdf}>
                {
                  download ? (
                    <span className="spinner-border spinner-grow-sm me-1" />
                  ) : (
                    <RiDownloadCloud2Fill size={20} />
                  )
                }
              </button> */}
              <div className="download">
                <a href={product?.pdf}>
                  {
                    download ? (
                      <span className="spinner-border spinner-grow-sm me-1" />
                    ) : (
                      <RiDownloadCloud2Fill size={20} />
                    )
                  }
                </a>
              </div>
            {/* </div> */}
          </div>
          <section className="pdf_view_container">
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                <div
                    style={{
                        height: '500px',
                        width: '98%',
                        overflowX: 'hidden',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    <Viewer
                        fileUrl={`${product?.pdf}`}
                        plugins={[defaultLayoutPluginInstance]}
                    />
                </div>
            </Worker>
          </section>
        </div>
      )}
      {error && <NotFound />}
    </div>
  );
};

export default ProductFlyer;
