import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FcCalendar } from 'react-icons/fc';
import { HiArrowRightCircle } from 'react-icons/hi2';
import * as dayjs from 'dayjs';
import { postedAgoDate } from '../utils';
import FallbackImage from './FallbackImage';

const PostHorizontal = (props) => {
  console.log('dddd', props.data);
  return (
    <div className="container">
      <div className="row">
        {props.data &&
          props.data.map((dat) => (
            <div
              className="col-lg-4 col-md-6 col-sm-12 col-12 img-container p-3"
              key={dat.id}
            >
              <Link to={{ pathname: `/posts/${dat.id}` }}>
                <Card className="post-card">
                  <Card.Body className="ps-0 pe-0">
                    <div className="d-flex ms-2">
                      <div>
                        {/* <Image src={dat.profile_picture} className='profile-image'/>     */}
                        <FallbackImage
                          path={dat.profile_picture}
                          title={'Agent Profile'}
                          className="profile-image"
                        />
                      </div>

                      <div className="ms-2">
                        <p className="card-name">{dat.agent_name}</p>
                        <p className="card-date" title="Posted Date">
                          <FcCalendar /> :{' '}
                          {postedAgoDate(dayjs(dat.created_at))}
                        </p>
                      </div>
                    </div>
                    <Card.Img
                      src={
                        dat.images.length > 0 && dat.images[0].path !== null
                          ? dat.images[0].path
                          : 'https://i.ibb.co/WcYXrpt/thumbnail.png'
                      }
                      className="post-image mt-3"
                    />
                    <p className="post-description p-3">
                      {/* { isNeedTruncate(dat.description) === true ? dat.description.substring(0,100) + ' ... '
                                            :
                                        dat.description
                                    } */}
                      {dat.description.substring(0, 31) + ' ... '}
                    </p>
                    <button className="post-detail-btn">
                      View Details&nbsp;
                      <HiArrowRightCircle />
                    </button>
                  </Card.Body>
                </Card>
              </Link>
            </div>
          ))}
      </div>
    </div>
  );
};

PostHorizontal.defaultProps = {
  data: []
};

export default PostHorizontal;
